var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{class:[
      'header',
      {
        'header--static': _vm.isMobileFixedMockup,
        'header--blackfriday': _vm.$store.state.isBlackFriday,
        'header--christmas': _vm.$store.state.isChristmas,
      } ]},[_c('div',{staticClass:"container header__container"},[_c('div',{staticClass:"header__nav"},[_c('a',{staticClass:"header__item header__item--hamburger header__item--mobile",style:({ position: 'absolute' }),attrs:{"href":"#","rel":"nofollow","aria-label":"Menu"},on:{"click":function($event){$event.preventDefault();}}},[_c('header-icon-hamburger')],1),_vm._v(" "),_c('nuxt-link',{class:[
            'header__item',
            'header__item--logo',
            {
              'header__item--logo-sudden':
                _vm.$isBrStore && !_vm.$store.state.isGocaseBirth,
              'header__item--logo-birth': _vm.$store.state.isGocaseBirth,
            } ],attrs:{"to":"/","aria-label":"Home"}},[_c('header-logo')],1),_vm._v(" "),_c('div',{staticClass:"header__item header__item--searchfield desktop-only",style:(!_vm.isLogged
              ? 'padding-left: 15px !important; padding-right: 15px !important;'
              : '')},[_c('new-header-search',{attrs:{"active":!_vm.mobileInputSearchOpened},on:{"close":_vm.closeMobileInputSearch}})],1),_vm._v(" "),(_vm.noContent)?_c('div',{staticClass:"header__item-100-seal"},[_c('sprite-tag',{attrs:{"tag":"selo_100-seguro"}})],1):_vm._e(),_vm._v(" "),(!_vm.noContent)?[(_vm.isLogged)?_c('nuxt-link',{class:[
              'header__item',
              'header__item--icon',
              { 'header__item--icon-birth': _vm.$store.state.isGocaseBirth } ],attrs:{"to":"/wishlist","aria-label":"Wishlist"}},[_c('icon-heart')],1):_vm._e(),_vm._v(" "),_c('nuxt-link',{class:[
              'header__item',
              'header__item--icon',
              { 'header__item--icon-birth': _vm.$store.state.isGocaseBirth } ],attrs:{"to":"/cart","aria-label":"Cart"}},[_c('header-cart',{attrs:{"qty":_vm.cartQty}})],1),_vm._v(" "),_c('user-widget',{class:[
              'header__item',
              'header__item--icon',
              { 'header__item--icon-birth': _vm.$store.state.isGocaseBirth } ]})]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"header__item header__item--desktop header__item--menu",class:{ 'header__item--blackfriday': _vm.$store.state.isBlackFriday },staticStyle:{"margin-left":"0px"}},[(_vm.showMenu)?_c('header-menu',{staticClass:"desktop-only"}):_vm._e()],1),_vm._v(" "),(!_vm.isSticky)?_c('div',{staticClass:"header__item header__item--searchfield mobile-only",staticStyle:{"margin":"0px 10px 0px 10px","padding":"0px"}},[_c('new-header-search',{attrs:{"active":!_vm.mobileInputSearchOpened},on:{"close":_vm.closeMobileInputSearch}})],1):_vm._e()])]),_vm._v(" "),_c('div',{class:[
      'header',
      'mobile-only',
      {
        'header--static': _vm.isMobileFixedMockup,
        'header--blackfriday': _vm.$store.state.isBlackFriday,
        'header--christmas': _vm.$store.state.isChristmas,
        'header--sticky': _vm.isSticky,
      } ]},[(_vm.isSticky)?_c('div',{staticClass:"container header__container"},[_c('div',{staticClass:"header__nav"},[_c('a',{staticClass:"header__item header__item--hamburger header__item--mobile",style:({ position: _vm.isSticky ? 'relative' : 'absolute' }),attrs:{"href":"#","rel":"nofollow","aria-label":"Menu"},on:{"click":function($event){$event.preventDefault();}}},[_c('header-icon-hamburger')],1),_vm._v(" "),(!_vm.isSticky)?_c('nuxt-link',{class:[
            'header__item',
            'header__item--logo',
            {
              'header__item--logo-sudden':
                _vm.$isBrStore && !_vm.$store.state.isGocaseBirth,
              'header__item--logo-birth': _vm.$store.state.isGocaseBirth,
            } ],attrs:{"to":"/","aria-label":"Home"}},[_c('header-logo')],1):_vm._e(),_vm._v(" "),(_vm.isSticky)?_c('div',{staticClass:"header__item header__item--searchfield mobile-only"},[_c('new-header-search',{attrs:{"active":!_vm.mobileInputSearchOpened},on:{"close":_vm.closeMobileInputSearch}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"header__item header__item--searchfield desktop-only"},[_c('new-header-search',{attrs:{"active":!_vm.mobileInputSearchOpened},on:{"close":_vm.closeMobileInputSearch}})],1),_vm._v(" "),(_vm.noContent)?_c('div',{staticClass:"header__item-100-seal"},[_c('sprite-tag',{attrs:{"tag":"selo_100-seguro"}})],1):_vm._e(),_vm._v(" "),(!_vm.noContent)?[(_vm.isLogged)?_c('nuxt-link',{class:[
              'header__item',
              'header__item--icon',
              { 'header__item--icon-birth': _vm.$store.state.isGocaseBirth } ],attrs:{"to":"/wishlist","aria-label":"Wishlist"}},[_c('icon-heart')],1):_vm._e(),_vm._v(" "),_c('nuxt-link',{class:[
              'header__item',
              'header__item--icon',
              { 'header__item--icon-birth': _vm.$store.state.isGocaseBirth } ],attrs:{"to":"/cart","aria-label":"Cart"}},[_c('header-cart',{attrs:{"qty":_vm.cartQty}})],1),_vm._v(" "),_c('user-widget',{class:[
              'header__item',
              'header__item--icon',
              { 'header__item--icon-birth': _vm.$store.state.isGocaseBirth } ]})]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"header__item header__item--desktop header__item--menu",class:{ 'header__item--blackfriday': _vm.$store.state.isBlackFriday },staticStyle:{"margin-left":"0px"}},[(_vm.showMenu)?_c('header-menu',{staticClass:"desktop-only"}):_vm._e()],1),_vm._v(" "),(!_vm.isSticky)?_c('div',{staticClass:"header__item header__item--searchfield mobile-only",staticStyle:{"margin":"0px 10px 0px 10px","padding":"0px"}},[_c('new-header-search',{attrs:{"active":!_vm.mobileInputSearchOpened},on:{"close":_vm.closeMobileInputSearch}})],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }