<template>
  <div
    v-click-outside="resetModalVisibility"
    :class="['header-search', { active }]"
  >
    <input
      ref="input"
      v-model="searching"
      type="search"
      name="q"
      autocomplete="off"
      autocapitalize="off"
      class="header-search__input"
      :placeholder="$t('header.search_placeholder')"
      @focus="openSearchOptions"
      @keydown.enter="handleSearchChange(searching)"
      @input="debouncedOnInput(searching)"
    />

    <a
      v-if="!isSearching"
      href="#"
      rel="nofollow"
      class="header-search header__search--icon"
      aria-label="Search"
      @click.prevent="handleSearchChange(searching)"
    >
      <icon-search />
    </a>

    <v-scrollable
      v-show="searchOptionsVisible"
      class="header-search__search-options"
      :items="searchOptions.items"
      :label="searchOptions.label"
      :show-text="(item) => item"
      left="0px"
      @change="handleSearchChange"
    />

    <three-dots-loading v-show="isSearching" class="header-search__spinner" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import ThreeDotsLoading from '~/components/loadings/three-dots'
import IconSearch from '~/components/icons/magnifier/Magnifier'
import VScrollable from '~/components/v-scrollable/VScrollable.vue'
import clickOutside from '~/mixins/click-outside'

export default {
  name: 'NewHeaderSearch',
  components: {
    ThreeDotsLoading,
    IconSearch,
    VScrollable,
  },
  mixins: [clickOutside],
  props: {
    active: Boolean,
  },
  data() {
    return {
      searching: this.$route.query.q || '',
      searchOptionsOpened: false,
    }
  },
  computed: {
    ...mapGetters({
      isSearching: 'searcher/isLoading',
      smartRedirectPath: 'searcher/getSmartRedirectPath',
      searchOptions: 'searcher/getSearchOptions',
      popularSearches: 'searcher/getPopularSearches',
    }),
    searchOptionsVisible() {
      return this.searchOptionsOpened && this.searchOptions?.items?.length > 0
    },
  },
  watch: {
    searching(query) {
      if (!query.trim()) {
        this.$store.dispatch('searcher/clearSuggestions')
      }
    },
    $route(to, from) {
      if (to.name !== 'search' && to.path !== this.smartRedirectPath) {
        this.clearSearchData()
      }
    },
  },
  methods: {
    clearSearchData() {
      this.searching = ''
      this.$emit('close')
    },
    openSearchOptions() {
      if (!this.popularSearches?.length)
        this.$store.dispatch('searcher/getPopularSearches')

      this.searchOptionsOpened = true
    },
    resetModalVisibility() {
      this.searchOptionsOpened = false
    },
    handleSearchChange(item) {
      this.$refs.input.blur()
      this.searching = item
      this.performSearch(item)
      this.resetModalVisibility()
    },
    performSearch(query) {
      if (!query.trim()) return

      const newFullPath = `/search?q=${query.trim()}`
      const currentFullPathDecoded = decodeURI(this.$route.fullPath)
      if (currentFullPathDecoded === newFullPath) return
      this.$store.dispatch('searcher/setLoading', true)
      this.$router.push(newFullPath)
    },
    debouncedOnInput: _.debounce(function (query) {
      if (!query.trim()) {
        this.$store.dispatch('searcher/clearSuggestions')
        return
      }
      this.$store.dispatch('searcher/fetchSuggestions', query)
    }, 300),
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/_utils.scss';

$search_height: 42px;

.header-search {
  display: flex;
  border: none;
  border-radius: 5px;
  align-items: center;
  padding: 0 ($search_height/2 - 7px)/2;
  height: $search_height;
  background-color: #eee !important;
  text-align: left;
  position: relative;

  &.header__search--icon {
    padding: 0px;
    text-align: center;
    background-color: transparent !important;

    svg {
      width: 26px;
      padding-top: 4px;
      fill: #555;
      border: none;
    }

    &:hover {
      color: #000;

      svg {
        fill: $v4_color_primary;
      }
    }

    @media (min-width: $screen_large) {
      svg {
        width: 28px;
      }
    }
  }

  .header-search__spinner {
    margin-inline: 20px;
  }

  .header-search__input {
    margin-left: 10px;
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 30px;
    padding: 0;
    font-size: 15px;
    border: 0;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-input-placeholder {
      color: darken($color_line, 20%);
    }

    &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }
  .header-search__icon {
    font-size: 16px;
  }

  .header-search__backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.5;
    z-index: 9;

    &.header-search__backdrop--open {
      display: block;
    }
  }
}

html .header-search {
  position: relative;
  opacity: 1;
  visibility: visible;
  background: #fff;
  height: 40px;
  margin-top: 0;
  padding: 0 ($search_height/2 - 7px)/2;
  z-index: 9;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .header-search__input {
    margin-left: 10px;
    font-size: 15px;
    height: 30px;
  }
}

@media (min-width: $screen_large) {
  html .header-search {
    height: $search_height;
    padding: 0 $search_height/2 - 5px;

    .header-search__input {
      font-size: 18px;
    }
  }
}

@media (min-width: $screen_larger) {
  html .header-search {
    border-radius: 5px;
  }
}
</style>
