<template>
  <header>
    <div
      :class="[
        'header',
        {
          'header--static': isMobileFixedMockup,
          'header--blackfriday': $store.state.isBlackFriday,
          'header--christmas': $store.state.isChristmas,
        },
      ]"
    >
      <div class="container header__container">
        <div class="header__nav">
          <a
            href="#"
            rel="nofollow"
            class="header__item header__item--hamburger header__item--mobile"
            aria-label="Menu"
            :style="{ position: 'absolute' }"
            @click.prevent
          >
            <header-icon-hamburger />
          </a>

          <nuxt-link
            to="/"
            :class="[
              'header__item',
              'header__item--logo',
              {
                'header__item--logo-sudden':
                  $isBrStore && !$store.state.isGocaseBirth,
                'header__item--logo-birth': $store.state.isGocaseBirth,
              },
            ]"
            aria-label="Home"
          >
            <header-logo />
          </nuxt-link>

          <div
            class="header__item header__item--searchfield desktop-only"
            :style="
              !isLogged
                ? 'padding-left: 15px !important; padding-right: 15px !important;'
                : ''
            "
          >
            <new-header-search
              :active="!mobileInputSearchOpened"
              @close="closeMobileInputSearch"
            />
          </div>

          <div v-if="noContent" class="header__item-100-seal">
            <sprite-tag tag="selo_100-seguro" />
          </div>

          <template v-if="!noContent">
            <nuxt-link
              v-if="isLogged"
              to="/wishlist"
              :class="[
                'header__item',
                'header__item--icon',
                { 'header__item--icon-birth': $store.state.isGocaseBirth },
              ]"
              aria-label="Wishlist"
            >
              <icon-heart />
            </nuxt-link>

            <nuxt-link
              to="/cart"
              :class="[
                'header__item',
                'header__item--icon',
                { 'header__item--icon-birth': $store.state.isGocaseBirth },
              ]"
              aria-label="Cart"
            >
              <header-cart :qty="cartQty" />
            </nuxt-link>

            <user-widget
              :class="[
                'header__item',
                'header__item--icon',
                { 'header__item--icon-birth': $store.state.isGocaseBirth },
              ]"
            />
          </template>
        </div>

        <div
          class="header__item header__item--desktop header__item--menu"
          style="margin-left: 0px"
          :class="{ 'header__item--blackfriday': $store.state.isBlackFriday }"
        >
          <header-menu v-if="showMenu" class="desktop-only" />
        </div>

        <div
          v-if="!isSticky"
          class="header__item header__item--searchfield mobile-only"
          style="margin: 0px 10px 0px 10px; padding: 0px"
        >
          <new-header-search
            :active="!mobileInputSearchOpened"
            @close="closeMobileInputSearch"
          />
        </div>
      </div>
    </div>

    <div
      :class="[
        'header',
        'mobile-only',
        {
          'header--static': isMobileFixedMockup,
          'header--blackfriday': $store.state.isBlackFriday,
          'header--christmas': $store.state.isChristmas,
          'header--sticky': isSticky,
        },
      ]"
    >
      <div v-if="isSticky" class="container header__container">
        <div class="header__nav">
          <a
            href="#"
            rel="nofollow"
            class="header__item header__item--hamburger header__item--mobile"
            aria-label="Menu"
            :style="{ position: isSticky ? 'relative' : 'absolute' }"
            @click.prevent
          >
            <header-icon-hamburger />
          </a>

          <nuxt-link
            v-if="!isSticky"
            to="/"
            :class="[
              'header__item',
              'header__item--logo',
              {
                'header__item--logo-sudden':
                  $isBrStore && !$store.state.isGocaseBirth,
                'header__item--logo-birth': $store.state.isGocaseBirth,
              },
            ]"
            aria-label="Home"
          >
            <header-logo />
          </nuxt-link>

          <div
            v-if="isSticky"
            class="header__item header__item--searchfield mobile-only"
          >
            <new-header-search
              :active="!mobileInputSearchOpened"
              @close="closeMobileInputSearch"
            />
          </div>

          <div class="header__item header__item--searchfield desktop-only">
            <new-header-search
              :active="!mobileInputSearchOpened"
              @close="closeMobileInputSearch"
            />
          </div>

          <div v-if="noContent" class="header__item-100-seal">
            <sprite-tag tag="selo_100-seguro" />
          </div>

          <template v-if="!noContent">
            <nuxt-link
              v-if="isLogged"
              to="/wishlist"
              :class="[
                'header__item',
                'header__item--icon',
                { 'header__item--icon-birth': $store.state.isGocaseBirth },
              ]"
              aria-label="Wishlist"
            >
              <icon-heart />
            </nuxt-link>

            <nuxt-link
              to="/cart"
              :class="[
                'header__item',
                'header__item--icon',
                { 'header__item--icon-birth': $store.state.isGocaseBirth },
              ]"
              aria-label="Cart"
            >
              <header-cart :qty="cartQty" />
            </nuxt-link>

            <user-widget
              :class="[
                'header__item',
                'header__item--icon',
                { 'header__item--icon-birth': $store.state.isGocaseBirth },
              ]"
            />
          </template>
        </div>

        <div
          class="header__item header__item--desktop header__item--menu"
          style="margin-left: 0px"
          :class="{ 'header__item--blackfriday': $store.state.isBlackFriday }"
        >
          <header-menu v-if="showMenu" class="desktop-only" />
        </div>

        <div
          v-if="!isSticky"
          class="header__item header__item--searchfield mobile-only"
          style="margin: 0px 10px 0px 10px; padding: 0px"
        >
          <new-header-search
            :active="!mobileInputSearchOpened"
            @close="closeMobileInputSearch"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderIconHamburger from './header-icon-hamburguer/HeaderIconHamburger.vue'
import HeaderLogo from './header-logo/HeaderLogo.vue'
import NewHeaderSearch from './header-search/NewHeaderSearch.vue'
import HeaderCart from './header-cart/HeaderCart.vue'
import HeaderMenu from './header-menu/HeaderMenu.vue'
import IconHeart from '~/components/icons/heart/Heart'
import UserWidget from '~/components/header/user-widget/UserWidget.vue'
import isPresent from '~/utils/isPresent'
import SpriteTag from '~/components/sprite-tag/SpriteTag.vue'

export default {
  name: 'NewHeader',
  components: {
    HeaderLogo,
    HeaderIconHamburger,
    HeaderMenu,
    NewHeaderSearch,
    HeaderCart,
    IconHeart,
    UserWidget,
    SpriteTag,
  },
  props: {
    noContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      helpCenterUrl: 'https://www.gocase.com/central-de-ajuda',
      mobileInputSearchOpened: false,
      isSticky: false,
    }
  },
  computed: {
    isMobileFixedMockup() {
      return this.$device.isMobile && this.$route.name === 'slug-p-case_device'
    },
    cartQty() {
      return this.$store.state.cartQty
    },
    isLogged() {
      return this.$store.getters.isLogged
    },
    showMenu() {
      return !this.noContent
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      this.isSticky = window.scrollY > 100
    },
    isPresent,
    openMobileInputSearch() {
      this.mobileInputSearchOpened = true
      setTimeout(() => {
        const searchInput = document.querySelector('[name=q]')
        searchInput?.focus()
      }, 50)
    },
    closeMobileInputSearch() {
      this.mobileInputSearchOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/_utils.scss';
@import '~/assets/css/_icon.scss';

@keyframes subtleBounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  box-shadow: 0 1px 0 0 #f1f4f8;
  background-color: white;
  top: 0;

  @media (max-width: $v4_desktop) {
    transition: background-color 0.3s ease;

    &--sticky {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      animation: subtleBounce 0.3s ease-out;
    }
  }

  &--static {
    position: static;
  }

  .header__item-100-seal {
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: 100px;
      height: 25px;
    }
  }

  .header__container {
    padding: 0;
  }

  a {
    color: #555;
    text-decoration: none;
  }

  .header__nav {
    display: flex;
    height: $nav_mobile_height;
    justify-content: flex-end;
  }

  .header__right {
    position: absolute;
    top: 0;
    right: 5px;
    display: flex;
  }

  .header__item {
    height: $nav_mobile_height;
    padding-right: 15px;
    align-content: center;

    &.header__item--searchfield {
      flex-grow: 1;
      width: auto;
    }

    &.header__item--desktop {
      display: none;
    }

    &.header__item--hamburger {
      width: auto;
      left: 0;
      padding-right: 0;
    }

    &.header__item--logo {
      display: block;
      padding-top: 16px;
      position: absolute;
      left: 60px;
    }

    &.header__item--logo-sudden {
      padding-top: 0;
    }

    &.header__item--logo-birth {
      padding-top: 13px;
    }

    &.header__item--icon {
      padding-top: 4px;
      text-align: center;

      svg {
        width: 26px;
        fill: #555;
      }
    }

    &.header__item--icon-birth {
      padding-top: 13px;
    }
  }
}

.header__item--desktop {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: $v4_color_orange;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.header__item--blackfriday {
  &::-webkit-scrollbar-thumb {
    background-color: $black_friday_color_primary !important;
  }
}

@media (min-width: $screen_large) {
  body {
    padding-top: 0;
  }

  .header {
    position: static;

    .header__nav {
      height: 80px;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .header__container {
      position: relative;
    }
    .header__item {
      width: auto;
      height: auto;
      font-size: 15px;
      padding-right: 0;
      padding-left: 0;
      margin-left: 30px;
      padding-bottom: 0;

      &.header__item--menu {
        flex-grow: 1;
        padding-bottom: 0;
      }

      &.header__item--desktop {
        display: block;
      }

      &.header__item--mobile {
        z-index: 1;
        display: none;
      }

      &.header__item--logo {
        padding-left: 0;
        position: static;
        margin: 0;
      }

      &.header__item--searchfield {
        flex-grow: 1;
        padding-top: 7px;
        padding-left: 0;
        padding-right: 0;
      }

      &.header__item--icon {
        padding-top: 13px;

        &:hover {
          color: #000;

          svg {
            fill: $v4_color_primary;
          }
        }

        svg {
          width: 28px;
        }
      }
    }

    .header__rewards-link,
    .header__vipsubscription-link {
      display: block;
    }
  }
}

.header {
  &--blackfriday {
    background: black;

    .header__item--logo {
      padding-bottom: 20px;
    }

    @media (max-width: $v4_desktop) {
      .header__item--logo {
        padding: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }

    a {
      color: white;
    }

    svg {
      fill: white !important;
    }
  }

  &--christmas {
    background: $christmas_color_primary;

    .header__item--logo {
      padding-bottom: 20px;
    }

    @media (max-width: $v4_desktop) {
      .header__item--logo {
        padding: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }

    a {
      color: white;
    }

    svg {
      fill: white !important;
    }
  }
}
</style>
