<template>
  <div class="default-layout">
    <client-only>
      <global-device-selector v-if="$globalDeviceIsActive" class="desktop-only"
    /></client-only>

    <topbar />

    <header-component v-if="evaluatedExperiment" />

    <new-header-component v-if="!evaluatedExperiment" />

    <!-- <lazy-hydrate
      v-slot="{ hydrated }"
      :trigger-hydration="$store.state.userInteracted"
    >
      <store-selector-modal v-if="hydrated" />
    </lazy-hydrate> -->

    <lazy-hydrate
      v-slot="{ hydrated }"
      :trigger-hydration="$store.state.userInteracted"
    >
      <drawer-menu v-if="hydrated" class="mobile-only" />
    </lazy-hydrate>

    <nuxt />

    <lazy-hydrate v-slot="{ hydrated }" when-visible>
      <div class="lazy_hydrate">
        <footer-component
          v-if="hydrated"
          :store-code="$store.state.storeCode"
        />
      </div>
    </lazy-hydrate>
    <cookie-modal />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import urlCouponApplier from '~/mixins/url-coupon-applier'
import Topbar from '~/components/Topbar.vue'
import HeaderComponent from '~/components/header/Header.vue'
import NewHeaderComponent from '~/components/header/NewHeader.vue'
import { CURRENCIES, LOCALES, STORE_CREDENTIALS } from '~/config'
import triggerOnUserInteraction from '~/utils/triggerOnUserInteraction'
import CookieModal from '~/components/cookie-modal/CookieModal.vue'
import growthBookMixin from '~/mixins/growthbook/growthBookMixin'

const GlobalDeviceSelector = () =>
  import(
    /* webpackChunkName: "global-device-selector"  */ '@/components/global-device-selector/GlobalDeviceSelector.vue'
  )

const DrawerMenu = () =>
  import(
    /* webpackChunkName: "drawer-menu"  */ '~/components/header/header-drawer/HeaderDrawer.vue'
  )
const FooterComponent = () =>
  import(/* webpackChunkName: "footer"  */ '~/components/footer/Footer.vue')

// const StoreSelectorModal = () =>
//   import(
//     /* webpackChunkName: "store-selector-modal"  */ '~/components/store-selector-modal/StoreSelectorModal.vue'
//   )

export default {
  layout: 'default',
  components: {
    LazyHydrate,
    Topbar,
    HeaderComponent,
    NewHeaderComponent,
    DrawerMenu,
    FooterComponent,
    // StoreSelectorModal,
    GlobalDeviceSelector,
    CookieModal,
  },
  mixins: [urlCouponApplier, pushDataLayer, growthBookMixin],
  errorCaptured(error, vm, info) {
    this.$sentry.captureException(error, { vm, info })
  },
  computed: {
    isMobileFixedMockup() {
      return this.$device.isMobile && this.$route.name === 'slug-p-case_device'
    },
    isLogged() {
      return this.$store.getters.isLogged
    },
    canonicalUrl() {
      return `https://www.gocase${this.$isBrStore ? '.com.br' : '.com'}${
        this.$route.path
      }`
    },
    gtmId() {
      const GTM_ID = STORE_CREDENTIALS[this.$store.state.storeCode]?.gtm?.id
      return GTM_ID
    },
  },
  async mounted() {
    await this.initializeGrowthBook('fixedSearch')

    this.$store.dispatch(
      'growthbook/updateEvaluatedExperiment',
      this.evaluatedExperiment
    )

    this.setCurrencyChanged()
    this.noIndexDisneyInGlobalWebsite()
    this.$store.dispatch('checkUserLogin')

    triggerOnUserInteraction(() => {
      this.$store.commit('SET_USER_INTERACTED')
      const cartModal = this.$route.query.cart_modal
      // eslint-disable-next-line camelcase
      const prismaLayoutTest = this.$route?.query?.prisma_test_version
      // eslint-disable-next-line camelcase
      const caseTypeLayoutTest = this.$route?.query?.ctui

      if (this.$globalDeviceIsActive || localStorage.getItem('global_device')) {
        this.setGlobalDevice()
      } else if (cartModal) {
        sessionStorage.setItem('cart_modal', cartModal)
      } else if (prismaLayoutTest) {
        sessionStorage.setItem('prisma_test_version', prismaLayoutTest)
      } else if (caseTypeLayoutTest) {
        sessionStorage.setItem('ctui', caseTypeLayoutTest)
      }

      this.$userReady.then((user) => {
        this.pushDataLayer({
          event: 'genericEvent',
          eventCategory: 'navigation',
          eventAction: 'userReady',
          eventValue: user,
        })
        if (!this.$store.state.gamification?.gamification) {
          this.$store.dispatch('gamification/updateGamification', {
            orderNumber: this.$store.state.orderNumber,
            storeInfo: this.$store.getters.storeInfo,
          })
        }
      })
    })
  },
  methods: {
    isPastNovFirst2023() {
      const currentTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/Sao_Paulo',
      })
      const currentDate = new Date(currentTime)

      const comparisonDateString = '2023-11-01T00:00:00'
      const comparisonDate = new Date(comparisonDateString + '-03:00') // Adiciona o fuso horário de Brasília (UTC-3)

      return currentDate >= comparisonDate
    },
    noIndexDisneyInGlobalWebsite() {
      if (
        this.$isGlobalStore &&
        (window.location.href.includes('disney') ||
          window.location.href.includes('dsn'))
      ) {
        const metaTag = document.createElement('meta')
        metaTag.name = 'robots'
        metaTag.content = 'noindex'

        const head = document.querySelector('head')
        head.appendChild(metaTag)
      }
    },
    async setGlobalDevice() {
      const storedGlobalDevice = localStorage.getItem('global_device')
      if (storedGlobalDevice) {
        this.$store.dispatch('setGlobalDevice', JSON.parse(storedGlobalDevice))
        return
      }

      if (this.$device.isDesktop || this.$device.isIos) return

      const globalDeviceImprovedLayers = this.$store.state.filteredGlobalDevice
        .improvedLayers
      const deviceModel = globalDeviceImprovedLayers[0]
      if (!deviceModel) return
      try {
        const { device } = await this.$axios.$get('/find_device', {
          params: {
            ...this.$store.getters.storeInfo,
            device_model: deviceModel,
          },
        })

        if (device) {
          localStorage.setItem('global_device', JSON.stringify(device))
          this.$store.dispatch('setGlobalDevice', device)
        }
      } catch (err) {
        this.$sentry.withScope((scope) => {
          scope.setExtra('RESULT', {
            globalDeviceLayers: globalDeviceImprovedLayers,
            ...(err.response.data || {}),
          })
          this.$sentry.captureMessage('Global Device is not defined')
        })
      }
    },
    currentHref() {
      return this.$store.state.currentHost + this.$route.fullPath
    },
    setCurrencyChanged() {
      const lastCurrency = this.$lastCurrency
      const newCurrency = this.$route?.query?.currency

      if (this.$isBrStore || !CURRENCIES.includes(newCurrency)) {
        return
      }

      this.$axios
        .$get('/currency/set', {
          params: {
            ...this.$store.getters.storeInfo,
            currency: lastCurrency || this.$currency,
            new_currency: newCurrency,
          },
        })
        .then((res) => {
          sessionStorage.setItem('v4_last_currency', newCurrency)
        })
        .catch((err) => {
          this.$displayTopMessage(this.$t('action_error'), { type: 'error' })
          this.$sentry.captureException(err.response || err)
        })
    },
  },
  head() {
    return {
      bodyAttrs: {
        class: [
          'goasyourself',
          this.isMobileFixedMockup ? 'static-header' : '',
          !this.evaluatedExperiment && !this.isMobileFixedMockup
            ? 'initial-search-visible'
            : '',
        ],
      },
      link: [
        {
          rel: 'canonical',
          href: this.canonicalUrl,
        },
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          rel: 'dns-prefetch',
          href: 'https://static-production.gocase.com.br',
        },
        { rel: 'preconnect', href: 'https://static-production.gocase.com.br' },
        {
          rel: 'dns-prefetch',
          href: this.$nuxt.$config.baseBrowserURL,
        },
        {
          rel: 'preconnect',
          href: this.$config.baseBrowserURL,
          crossorigin: 'use-credentials',
        },
        { rel: 'dns-prefetch', href: 'https://ik.imagekit.io' },
        { rel: 'preconnect', href: 'https://ik.imagekit.io' },
        {
          rel: 'preload',
          as: 'style',
          href: require('~/assets/fonts/Lato-Black.ttf'),
          crossorigin: 'anonymous',
        },
        {
          rel: 'preload',
          as: 'style',
          href: require('~/assets/fonts/Lato-Regular.ttf'),
          crossorigin: 'anonymous',
        },
        ...(this.$i18n.locale === 'pt-BR'
          ? [
              {
                rel: 'alternate',
                hreflang: 'pt-br',
                href: this.currentHref(),
              },
            ]
          : LOCALES.filter((loc) => loc !== 'pt-BR').map((loc) => ({
              rel: 'alternate',
              hreflang: loc,
              href: this.currentHref(),
            }))),
      ],
    }
  },
}
</script>

<style lang="scss">
.initial-search-visible {
  @media (max-width: $screen_large) {
    padding-top: calc(51px + 48px) !important;
  }
}
</style>
