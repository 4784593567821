<template>
  <ul
    class="main-menu"
    :class="{ 'main-menu--fixed-search': !evaluatedExperiment }"
  >
    <header-menu-content-item
      v-for="item in featuredItems"
      :key="item.position"
      :item="item"
      :featured="true"
    />
    <header-menu-content-item
      v-for="item in navItems"
      :key="item.position"
      :item="item"
    />
  </ul>
</template>

<script>
import copyObject from '@/commun/utils/copyObject'
import { mapGetters } from 'vuex'
import HeaderMenuContentItem from './header-menu-content-item/HeaderMenuContentItem.vue'

export default {
  name: 'HeaderMenuContent',
  components: {
    HeaderMenuContentItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      evaluatedExperiment: 'growthbook/getEvaluatedExperiment',
    }),
    featuredItems() {
      const reference = Array.from(this.items)
      const featured = []
      featured.push(reference.shift())

      // eslint-disable-next-line camelcase
      const ctaMenu = copyObject(this.$store.state.siteStore?.cta_menu?.[0])

      if (ctaMenu) {
        ctaMenu.position = 0.1
        featured.push(ctaMenu)
      }

      return featured
    },
    navItems() {
      const reference = Array.from(this.items)
      reference.shift()
      return reference.filter((ref) => ref.name_slug !== 'track-order')
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/css/main-menu.scss';

.header {
  a {
    color: #555;
    text-decoration: none;
  }
}
</style>
